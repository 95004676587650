.pricing-ctr {
  min-height: 100vh;
  background-color: #7e98b9;
  width: 100%;
  /* background: radial-gradient(ellipse at center, #FEECEA, #6D7D9E); */
  /* background: radial-gradient(circle, #FAEAEB, #232F61); */
  padding: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ctr-header {
  text-align: center;
  width: 40%;
  font-weight: 900;
  text-shadow: 2px 0 0 rgba(0, 0, 0, 0.5);
  font-size: 2.5rem;
  /* color: #EBF5FB; */
}

.ctr-subheader {
  margin-top: 20px;
  font-size: 1.2rem;
  letter-spacing: 1px;
  color: #ebf5fb;
}

.card_holder {
  display: flex;
  flex-direction: row;
  width: 70%;
  justify-content: space-between;
  margin-top: 50px;

  .card-ctr, .mobile_card-ctr {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    /* flex: 1; */
    margin: 0 20px;
    /* border: 1px solid red; */
    border-radius: 10px;
    color: #3f5885;
    padding: 20px;
    text-align: center;
    /* height: auto; */
    background-color: #ebf5fb;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;

    .plan-type {
      font-weight: 900;
      font-size: 2rem;
      margin: 20px 0;
    }
  }
}

.plan_btn {
  align-self: flex-start;
  font-size: 1.15rem;
  padding: 10px 15px;
  border-radius: 5px;
  border: none;
  background-color: #3f5885;
  margin-top: 10px;
  color: white;
  cursor: pointer;
  text-decoration: none;
}

.plan_benefits {
  margin-top: 25px;

  .benefit {
    display: flex;
    text-align: left;
    align-items: center;
    margin-bottom: 20px;

    .icon {
      margin-right: 15px;
      font-size: 1.5rem;
      color: #3f5885;
    }
  }
}

.tabs-container {
  display: none;
  /* display: flex; */
  justify-content: space-around;
  /* background-color: rebeccapurple; */
  width: 90%;
  margin: 20px 0;
  font-size: 1.2rem;
  /* color: #ebf5fb; */
  color: #3f5885;
  font-weight: 700;
  margin-top: 50px;
}

.active-tab {
    color: #ebf5fb;
    border-bottom: 3px solid #ebf5fb;
}

.go-premium {
  /* text-align: left; */
  align-self: flex-start;
  margin-top: 10px;
  text-decoration: none;
  color: #3f5885;
}

.go-premium span {
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
  color: #3366FF;
}

.not-allowed {
  text-decoration: line-through;
  color: #979797;
}

@media only screen and (max-width: 600px) {
  
  .ctr-header {
    width: 90%;
    font-weight: 700;
    font-size: 2rem;
  }

  .pricing-ctr {
    padding: 0;
    padding-top: 10vh;
  }
}

/* Small Devices (tablets) */
@media only screen and (max-width: 600px) {
  /* Your styles for small devices go here */
  .pricing-ctr {
    padding-top: 10vh;
    /* Remove or set height to auto */
    /* height: auto; */
    min-height: 100vh;
    max-height: 200vh;
    background: linear-gradient(to bottom, #7e98b9, #7e98b9);
    width: 100%;
    /* background-image: url('../Assets/background.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    backdrop-filter: blur(20px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ctr-subheader {
    text-align: center;
  }

  .card_holder {
    margin-top: 10px;
    width: 80%;
  }

  .card-ctr {
    margin: 0 !important;
  }

  .mobile_card-ctr {
    display: block;
  }

  .hidden {
    display: none !important;
  }

  .tabs-container {
    display: flex;
  }
}

/* Medium Devices (desktops) */
@media only screen and (min-width: 700px) and (max-width: 999px) {
  /* Your styles for medium devices go here */
  .card_holder {
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .card-ctr {
    width: 35%;
  }
}

/* Large Devices (desktops) */
@media only screen and (min-width: 1000px) and (max-width: 1199px) {
  /* Your styles for large devices go here */
  .card_holder {
    width: 90%;
  }
}

/* Extra Large Devices (large desktops) */
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  /* Your styles for extra large devices go here */
}
