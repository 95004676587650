.DashBoardBar {
    height: 6.5vh;
    /* background-color: aqua; */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 10px;
    background-color: var(--background-color-500);
    margin-bottom: 5px;
    box-shadow: var(--shadow-primary);
}

.verticalDivider {
    height: 50%;
    padding: 0 1px;
    border-radius: 0.5px;
    /* width: 1.5px; */
    background-color: var(--border-color-500);
    margin: 0 5px;
}

.menu-ctr {
    padding: 0 10px;
    height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: all 0.1s ease-in;

    .menu-icon {
        font-size: 1.5rem;
    }

    &:hover {
        background-color: var(--tab-Hover-color);
        cursor: pointer;
    }

    &:active {
        scale: 0.96;
    }
}

.searchtrade-ctr {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.1rem;
    /* padding: 1vh 10px; */
    transition: all 0.1s ease-in;
    border-radius: 5px;
    padding: 0 10px;
    height: 85%;

    .searchtrade-icon {
        /* color: var(--text-color-500); */
        color: var(--text-color-100);
        font-weight: bold;
        font-size: 1.2rem;
    }

    .searchtrade-name {
        margin: 0;
        font-weight: 600;
        margin-left: 5px;
        letter-spacing: 1px;
        color: var(--text-color-100);
    }

    &:hover {
        background-color: var(--background-color-900);
        cursor: pointer;
    }

    &:active {
        scale: 0.96;
    }
}



.avatar_centre {
    position: absolute;
    left: -15px;
    border: 2px solid var(--border-color-100);
    top: 50%;
    transform: translate(-50%, -50%);
    /* height: 90%; */
    /* width: 10% */
}

.logout {
    margin-left: auto;
    background-color: inherit;
    min-width: 400px;
    width: auto;
    max-width: 300px;
    color: var(--text-color-100);
    display: flex;
    justify-content: flex-end
}
