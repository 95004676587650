.dashboard_container {
    /* background: linear-gradient(to bottom, var(--background-white),var(--background-color-200), var(--background-color-200));width: 100%; */
    background-color: var(--background-color-100);
    height: 100vh;
    overflow: hidden;
}

.section_container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
    /* padding: 20px; */
}

.dashboard_chart_ctr {
    display: flex;
    flex: 1;
}

.dashboard_chart {
    /* margin: 0 30px; */
    width: 100%;
    height: calc(100vh - 8vh);
    border-radius: 7px;
    overflow: hidden;
    box-shadow: var(--shadow-primary);
}

.pagination_ctr {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 20px 10px 20px 0;
    align-items: center;

    .pagination_text {
        margin: 0 10px;
        font-size: 1rem;
    }
}
