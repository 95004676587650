.nav_bar-ctr {
  align-self: center;
  height: 10vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 100;
  transition: all 0.15s ease-in-out;
}

.fixed {
  background-color: #54749d7c;
  backdrop-filter: blur(15px);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  z-index: 1000;
  height: 8vh;

  .logo {
    scale: 0.7;
  }
}

.logo {
  width: 50px;
  /* border-radius: 30px; */
  margin-left: 20px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

.link-ctr {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: row;
  width: 50%;
  justify-content: space-evenly;

  .link {
    color: white;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    &:hover {
      color: #0f4c81;
    }
  }

  .active {
    color: #0f4c81;
  }
}

.auth-btn {
  text-decoration: none;
  font-size: 0.85rem;
  letter-spacing: 1px;
  padding: 10px 30px;
  border-radius: 50px;
  width: 150px;
  background-color: transparent;
  transition: all 0.2s ease-in;
  cursor: pointer;
  color: white;
  border: 2px solid white;
  font-weight: bold;
  border-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  &:last-child {
    margin-left: 10px;
    margin-right: 20px;
  }

  &:hover {
    background-color: white;
    color: black;
    border-color: #424242;
  }
}

.nav_menu {
    font-size: 2rem;
    margin-right: 20px;
    color: #ebf5fb;
    display: none;
}

.mobile_nav-ctr {
    background-color: #ebf5fb;
    backdrop-filter: blur(15px);
    position: absolute;
    width: 100%;
    /* bottom: 10px; */
    margin-top: 62.22vh;
    bottom: 100px;
    transition: all 0.15s ease-in-out;
    backdrop-filter: blue(20px);
    display: none;
    /* z-index: 9999; */
    /* position: fixed; */
}

.scrolled {
    margin-top: 59.22vh;
}

.mobile-nav-links {
    backdrop-filter: blur(15px) !important;
    /* height: 50px; */
    color: #3f5885;
    font-size: 1.2rem;
    /* font-weight: 600; */
    text-align: center;
    padding: 20px 0;
}

.mobile-login {
    background-color: #3f5885;
    color: #ebf5fb;
}

.navopen {
    bottom: -380px;
    transition: all 0.15s ease-in-out;
}

/* 0 - 600px Screen Styles */
@media only screen and (max-width: 600px) {
    /* .container {
        position: relative;
    } */
    .nav_menu {
        display: block;
    }
    .mobile_nav-ctr {
        display: block;
    }

    .nav_bar-ctr {
        z-index: 9999;
    }

  .link-ctr {
    display: none;
  }

  .auth-ctr {
    display: none;
  }
}
