.plan-main-ctr {
    background-color: var(--background-color-500);
    height: auto;
    width: 100%;
    overflow: scroll;
}

.pricing-holder {
    margin-bottom: 20vh;
    align-self: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: aquamarine; */
}

.all-pricing-header {
    width: 100% !important;
    margin-top: 30px;
    color: var(--text-color-100);
}

.spin-loading {
    margin-right: 10px;
}

.ant-spin-dot-item {
    background-color: var(--background-color-100) !important;
}