.dashboard_right-section {
  height: calc(100vh - 8vh);
  width: 40%;
  background-color: var(--background-color-500);
  color: var(--text-color-100) !important;
  border-radius: 7px;
  box-shadow: var(--shadow-primary);
  overflow-y: scroll;
  margin-left: 10px;
  transition: all 0.2s ease-in-out;

  position: relative;
  justify-content: center;
  align-items: center;

  .expand_section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 20px;
    background-color: var(--background-color-900);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(-50%);

    border-radius: 0 10px 10px 0;
    cursor: pointer;
    z-index: 100;
    /* position: absolute; */
    right: -30px;
    /* border: 1px solid rgb(61, 61, 61); */

    .expand_icon {
      color: var(--text-color-100);
      font-weight: bold;
    }
  }
}

.turning_point-ctr {
  display: flex;
  flex-direction: row;

  .turning_point-text {
    font-weight: 600;
    letter-spacing: 0.5px;
    font-size: 1rem;
    margin: 0;
  }

  .turning_point-icon {
    font-size: 1.3rem;
    margin-left: auto;
    padding-left: 15px;
    color: var(--text-color-500);

    &:hover {
      cursor: pointer;
    }

    &:active {
      scale: 0.9;
    }
  }

  .upload-icon {
    margin-left: 0;
    color: var(--text-color-500);
  }

  .delete-icon {
    margin-left: 0;
    color: var(--danger-color-500);
  }
}

.turningpoint_modal-ctr {
  min-height: 30vh;

  .turningpoint_btn-ctr {
    display: flex;
    flex-direction: row;

    .csv-btn {
      letter-spacing: 1px;

      &:last-child {
        margin-left: 20px;
      }
    }
  }
}
.marked_point-ctr {
  /* background-color: antiquewhite; */
  padding: 10px 5px;
  margin: 0 10px;
  display: flex;
  border-radius: 5px;
  flex-direction: row;
  transition: background-color 0.15s ease-in-out;
  border-bottom: 1px solid var(--border-color-200);

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: var(--background-color-100);
    cursor: pointer;
  }

  .marker_text {
    margin: 0;

    &:first-child {
      color: var(--background-color-600);
    }

    /* font-weight: 600; */

    .marker_date {
      margin-left: 10px;
      font-weight: normal;
      color: var(--text-color-black);
    }
  }

  .delete_icon {
    margin-left: auto;
    padding-left: 15px;
    color: var(--danger-color-500);

    &:active {
      scale: 0.9;
    }
  }
}
.tag {
  color: var(--text-color-600);
  background-color: var(--background-color-100);
  border-color: var(--border-color-300);
  /* font-size: 1rem; */
  padding: 5px 10px;
  text-align: center;
  /* margin-bottom: 10px; */

  &:hover {
    cursor: pointer;
    scale: 1.05;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }

  &:active {
    scale: 1;
  }
}

.ant-divider {
  border-block-start: 1px solid var(--border-color-100);
}

.ant-card {
  border-color: var(--border-color-200);
}

.moon_phase-dates {
  text-align: center;
}

.color_ctr {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.color_sub-ctr {
  display: flex;
  flex-direction: row;
  align-items: center;

  .circle {
    height: 8px;
    aspect-ratio: 1;
    border-radius: 8px;
    margin-right: 5px;
  }
}

.moon_phase-collapse {
  background-color: var(--background-color-white);
}

.moon_phase-card {
  position: relative;

  .moon_phase-checkbox {
    position: absolute;
    right: 0;
    top: 10px;
  }
}

.moon_phase-dates-ctr {
  display: grid;
  grid-template-columns: auto auto auto;
  /* column-gap: 10px; */
  row-gap: 10px;
}

.moon_phase_ctr {
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 10px;
  row-gap: 5px;

  .text {
    /* color: var(--text-color-300); */

    &:hover {
      transition: all 0.2s ease-in-out;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .text span {
    font-weight: 700;
    color: var(--text-color-500);
  }
}
.collapseHeaderCtr {
  display: flex;
  flex-direction: column;
  /* background-color: red; */
  /* flex-direction: row; */
  /* width: auto; */
}

.collapseHeaderCtr:first-child {
  /* margin: 0 5px;
    width: 40%;
    margin-right: 20px; */
}

.collapseHeaderContent {
  /* background-color: blue; */
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 5px;
}

/* .collapseHeaderCtr h5:first-child {
    flex: 1;
}


.collapseHeaderCtr h5:nth-child(2) {
    margin-right: 20px;
    font-size: 0.7rem;
}

.collapseHeaderCtr h5:nth-child(3) {
    margin-right: 20px;
    font-size: 0.7rem;
}
.collapseHeaderCtr h5:nth-child(4) {
    margin-right: 20px;
    font-size: 0.7rem;
}
*/
.filter_ctr {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  /* margin-top: 20px; */
}
.filter_icon {
  /* font-size: 1.3rem; */
  margin: 10px;
  /* transition: scale 0.1s ease-in-out;

    &:hover {
        cursor: pointer;
    }

    &:active {
        scale: 0.9;
    } */
}

.filter_form_buttons-ctr {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  .form-item {
    margin: 0;
  }

  .form-buttons {
    width: 100px;
  }

  .form-buttons:nth-child(1) {
    margin-left: 20px;
  }
}
.collapse_icon {
  padding-left: 5px;
  font-size: 16px;
  color: var(--text-color-500);
}

.collapse_icon-close {
  color: var(--danger-color-600);
}
.angle_text-ctr {
  display: grid;
  grid-template-columns: auto auto;
}

.angle_text {
  /* font-weight: 600; */
  letter-spacing: 1px;
  margin: 5px 0;
}

.angle_text span {
  font-weight: 600;
  color: var(--text-color-500);
  margin-left: 5px;
}

.checkbox-ctr {
  margin: 10px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-color-100) !important;
  letter-spacing: 0.8px;
  /* font-size: 1.1rem; */

  .customCheckbox {
    height: 16px;
    aspect-ratio: 1 / 1;
    background-color: var(--background-color-100);
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
  }

  .checked {
    background-color: var(--background-color-900);
  }

  .customCheck {
    color: var(--text-color-100) !important;
    font-size: 14px !important;
  }

  .uncheck {
    display: none;
  }

  /* .ant-checkbox-input {
    background-color: red !important;
    color: red !important;
  }

  .ant-checkbox {
    background-color: red !important;
  }

  .ant-checkbox-inner::after {
    background-color: red;
  } */

  .dot {
    height: 10px;
    aspect-ratio: 1;
    border-radius: 10px;
    margin-left: 10px;
  }
}

